<template>
  <div class="AiVideo">
    <div class="left_box">
      <div class="left_top">
        <div class="btn_column">
          <div
            :class="['btn_item', { active_btn: currentBtnIndex === index }]"
            v-for="(value, index) in btnList"
            :key="index"
            @click="currentBtnIndex = index"
          >
            {{ value.text }}
          </div>
        </div>
        <div class="config_column">
          <!-- 通用 -->
          <div v-show="currentBtnIndex === 0">
            <div class="config_title">
              选择车系
              <!-- <span style="color: #d9011c">*</span> -->
              <a-tooltip placement="right">
                <template slot="title">
                  <div>用于筛选对应车系的素材</div>
                </template>
                <span style="cursor: pointer">
                  <a-icon type="question-circle" style="margin-left: 5px; color: #0a0a0a" />
                </span>
              </a-tooltip>
            </div>
            <div style="display: flex; justify-content: space-between; margin-bottom: 15px">
              <BrandSelectInput
                style="width: 48%"
                placeholder="品牌"
                v-model="params.principalIdListData"
                show-search
                :maxTagCount="2"
                option-filter-prop="children"
                allowClear
                @onChange="params.carSeriesIdListData = undefined"
              />
              <CarSeriesSelectInput
                :disabled="!params.principalIdListData"
                style="width: 48%"
                :principalIds="principalIds"
                v-model="params.carSeriesIdListData"
                placeholder="车系"
                show-search
                :filter-option="false"
                allowClear
              />
            </div>
            <div class="config_title">视频比例<span style="color: #d9011c">*</span></div>
            <div>
              <span
                :class="['config_tab_item', params.videoAspect === '9:16' ? 'active_item' : '']"
                @click="params.videoAspect = '9:16'"
                >9:16</span
              >
              <span
                :class="['config_tab_item', params.videoAspect === '16:9' ? 'active_item' : '']"
                @click="params.videoAspect = '16:9'"
                >16:9</span
              >
            </div>
          </div>
          <!-- 文案 -->
          <div v-show="currentBtnIndex === 1">
            <div class="config_title">文案<span style="color: #d9011c">*</span></div>
            <a-button type="default" style="margin: 5px 0 10px; width: 100%" @click="handleOpenAi">AI写文案</a-button>
            <div style="position: relative">
              <a-textarea
                placeholder="请输入文案，可用于配音或字幕"
                :auto-size="{ minRows: 10, maxRows: 20 }"
                v-model.trim="params.videoScript"
                :maxLength="600"
              ></a-textarea>
              <div class="word_count">{{ (params.videoScript && params.videoScript.length) || 0 }} / 600</div>
            </div>
          </div>
          <!-- 视频 -->
          <div v-show="currentBtnIndex === 2">
            <div class="config_title">
              素材类型<span style="color: #d9011c">*</span>
              <a-tooltip placement="right">
                <template slot="title">
                  <div>
                    视频素材Ai匹配，暂不可自由选择
                  </div>
                </template>
                <span style="cursor: pointer">
                  <a-icon type="question-circle" style="margin-left: 5px; color: #0a0a0a" />
                </span>
              </a-tooltip>
            </div>
            <div>
              <span
                :class="['config_tab_item', { active_item: params.materialType === v.type }]"
                v-for="(v, i) in materialTypesList"
                :key="i"
                @click="params.materialType = v.type"
                >{{ v.display }}</span
              >
            </div>
          </div>
          <!-- 配音 -->
          <div v-show="currentBtnIndex === 3">
            <div>
              <div
                :class="['config_tab_item', { active_item: params.currentDubbingIndex === index }]"
                style="background-color: #f1f3f4; cursor: default"
                v-for="(item, index) in dubbingList"
                :key="index"
                @click.stop="handleIndexClick(index, 'currentDubbingIndex')"
              >
                <a-checkbox class="current_check_box" :checked="params.currentDubbingIndex === index"></a-checkbox>
                <div style="margin-bottom: 10px; cursor: pointer">{{ item.name }}</div>
                <!-- <div style="margin-bottom: 10px; cursor: pointer"> - </div> -->
                <audio style="width: 100%" controls :src="item.previewOssAddress"></audio>
              </div>
              <!-- <audio id="myAudio" style="width: 100px" controls controlsList="nodownload noplaybackrate nomute">
                  <source :src="item.previewOssAddress" type="audio/mpeg" />
                </audio> -->
            </div>
          </div>
          <!-- 音乐 -->
          <div v-show="currentBtnIndex === 4">
            <div style="display: flex; align-items: center">
              <span>音量</span>
              <span style="margin: 0 10px; width: 180px">
                <a-slider v-model="params.bgmVolume" />
              </span>
              <span>{{ params.bgmVolume }}%</span>
            </div>
            <div class="music_list">
              <div
                :class="['music_item', { active_item: params.currentMusicIndex === index }]"
                v-for="(item, index) in musicList"
                :key="index"
                @click.stop="handleIndexClick(index, 'currentMusicIndex')"
              >
                <a-checkbox class="current_check_box" :checked="params.currentMusicIndex === index"></a-checkbox>
                <div style="font-weight: bolder">{{ item.name }}</div>
                <div>-</div>
              </div>
            </div>
          </div>
          <!-- 字幕 -->
          <div v-show="currentBtnIndex === 5">
            <a-checkbox style="margin-bottom: 15px" v-model="params.subtitleEnabled"
              ><span style="user-select: none">生成字幕</span></a-checkbox
            >

            <div v-show="params.subtitleEnabled">
              <div>字体</div>
              <a-select style="margin: 10px 0 15px 0; width: 100%" placeholder="请选择字体" v-model="params.fontName">
                <a-select-option v-for="(v, i) in fontsList" :key="i" :value="v">{{ v }}</a-select-option>
              </a-select>
              <div>字幕位置</div>
              <a-select
                style="margin: 10px 0 15px 0; width: 100%"
                placeholder="请选择字体"
                v-model="params.subtitlePosition"
              >
                <a-select-option v-for="(v, i) in positionList" :key="i" :value="v.value">{{ v.text }}</a-select-option>
              </a-select>

              <div class="color_box">
                <div class="color_left">
                  <div style="margin-bottom: 10px">字幕颜色</div>
                  <a-popover v-model="showTextColor" title="" trigger="click" placement="right">
                    <div slot="content">
                      <Sketch v-model="params.textForeColor" @input="(value) => (params.textForeColor = value.hex)" />
                    </div>
                    <div
                      class="color_item"
                      :style="`background-color: ${params.textForeColor};`"
                      @click="showTextColor = true"
                    ></div>
                  </a-popover>
                </div>
                <div class="color_right">
                  <div>
                    字幕大小 <span style="margin-left: 10px; font-weight: bolder">{{ params.fontSize }}</span>
                  </div>
                  <div>
                    <a-slider
                      :marks="{
                        0: '0',
                        100: '100',
                      }"
                      v-model="params.fontSize"
                    />
                  </div>
                </div>
              </div>

              <div class="color_box">
                <div class="color_left">
                  <div style="margin-bottom: 10px">描边颜色</div>
                  <a-popover v-model="showStrokeColor" title="" trigger="click" placement="right">
                    <div slot="content">
                      <Sketch v-model="params.strokeColor" @input="(value) => (params.strokeColor = value.hex)" />
                    </div>
                    <div
                      class="color_item"
                      :style="`background-color: ${params.strokeColor};`"
                      @click="showStrokeColor = true"
                    ></div>
                  </a-popover>
                </div>
                <div class="color_right">
                  <div>
                    描边粗细 <span style="margin-left: 10px; font-weight: bolder">{{ params.strokeWidth }}</span>
                  </div>
                  <div>
                    <a-slider
                      :marks="{
                        0: '0',
                        10: '10.00',
                      }"
                      :min="0"
                      :max="10"
                      :step="0.01"
                      v-model="params.strokeWidth"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="left_bottom">
        <a-button type="primary" :loading="submitLoading" @click="handleSubmit">生成视频</a-button>
      </div>
    </div>
    <div class="right_box">
      <div>已选配置</div>
      <div class="show_item" v-if="params.principalIdListData || params.carSeriesIdListData">
        <span style="margin-right: 100px" v-if="params.principalIdListData"
          ><span class="item_title">品牌：</span><span>{{ getTextName(params.principalIdListData) }}</span></span
        >
        <span v-if="params.carSeriesIdListData"
          ><span class="item_title">车系：</span><span>{{ getTextName(params.carSeriesIdListData) }}</span></span
        >
        <!-- <span class="info_logo" v-show="!params.principalIdListData || !params.carSeriesIdListData"
          ><a-icon type="exclamation"
        /></span> -->
      </div>
      <div class="show_item">
        <span class="item_title"> 视频比例：</span><span>{{ params.videoAspect }}</span>
      </div>
      <div class="show_item"><span class="item_title"> 成片数量：</span><span>1</span></div>
      <div class="show_item">
        <span class="item_title"> 文案：</span>
        <span>已输入 {{ params.videoScript ? params.videoScript.length : 0 }} 字</span>
        <!-- <span class="info_logo" v-show="!params.videoScript"><a-icon type="exclamation" /></span> -->
      </div>
      <div class="show_item" v-if="params.currentDubbingIndex >= 0">
        <span class="item_title">
          配音：<span>{{ dubbingList[params.currentDubbingIndex].name }}</span></span
        >
      </div>
      <div class="show_item" v-if="params.currentMusicIndex >= 0">
        <span class="item_title"> 音乐：</span
        ><span>{{ params.bgmVolume }}% - {{ musicList[params.currentMusicIndex].name }}</span>
      </div>
      <div class="show_item" v-show="params.fontName && params.subtitleEnabled">
        <span class="item_title">
          字幕：<span>{{ params.fontName }}</span></span
        >
      </div>
    </div>

    <a-drawer
      title="AI写文案"
      :width="660"
      :visible="showAiDrawer"
      :destroyOnClose="true"
      :body-style="{ paddingBottom: '0' }"
      @close="showAiDrawer = !showAiDrawer"
    >
      <a-spin :spinning="aiLoading">
        <div class="iframe_container">
          <iframe
            id="AiIframe"
            class="iframe"
            allow="cross-origin-isolated; clipboard-read; clipboard-write"
            sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
            frameborder="0"
            :src="chatLink"
          ></iframe>
        </div>
      </a-spin>
    </a-drawer>
  </div>
</template>

<script>
import { btnList, positionList } from './data';
import { Sketch } from 'vue-color';
import BrandSelectInput from '@/components/xhsAgencyTool/BrandSelectInput';
import CarSeriesSelectInput from '@/components/xhsAgencyTool/CarSeriesSelectInput';
import api from '@/api/xhsAgencyApi';

export default {
  name: 'AiVideo',
  props: {},
  components: {
    Sketch,
    BrandSelectInput,
    CarSeriesSelectInput,
  },
  data() {
    return {
      btnList,
      positionList,
      currentBtnIndex: 0,
      params: {
        principalIdListData: undefined,
        carSeriesIdListData: undefined,
        videoAspect: '9:16',
        videoScript: undefined,
        materialType: 'picture',
        bgmVolume: 20,
        currentDubbingIndex: undefined,
        currentMusicIndex: undefined,
        subtitleEnabled: false,
        fontName: undefined,
        subtitlePosition: 'bottom',
        textForeColor: '#FFFFFF',
        strokeColor: '#000000',
        fontSize: 60,
        strokeWidth: 1.5,
      },
      showAiDrawer: false,
      dubbingList: [],
      musicList: [],
      fontsList: [],
      materialTypesList: [],
      showTextColor: false,
      showStrokeColor: false,
      submitLoading: false,
      aiLoading: false,
    };
  },
  computed: {
    principalIds() {
      if (!this.params.principalIdListData) return [];
      const isArray = Array.isArray(this.params.principalIdListData);
      let principalIdListData = this.params.principalIdListData;
      if (!isArray) {
        principalIdListData = [this.params.principalIdListData];
      }
      return principalIdListData.length > 0
        ? principalIdListData.map((item) => {
            const arr = item.split(',');
            return arr.length > 0 ? arr[0] : '';
          })
        : [];
    },
    chatLink() {
      return `${process.env.VUE_APP_GPTS_URL}/chat/7c64a0beeb044782a8c63e763d3f5db1?from=bfz`;
    },
  },
  watch: {},
  methods: {
    // 获取材料列表
    async getMaterialList() {
      const { code, data, message } = await api.getAiVideoMaterialList();
      if (code === 200) {
        this.dubbingList = data.dubbing;
        this.fontsList = data.fonts;
        if (this.fontsList.length > 0) this.params.fontName = this.fontsList[0];
        this.materialTypesList = data.materialTypes;
        this.musicList = data.music;
      } else {
        this.$message.error(message);
      }
    },
    async handleSubmit() {
      // if (!this.params.principalIdListData || !this.params.carSeriesIdListData)
      //   return this.$message.error('请选择品牌和车系');
      if (!this.params.videoScript) return this.$message.error('请输入文案');
      if (this.submitLoading) return;
      const { videoScript, materialType, videoAspect, subtitleEnabled } = this.params;
      const params = {
        aiTaskType: 'VIDEO',
        externalTaskConfig: {
          videoSubject: '',
          videoScript,
          videoTerms: '',
          videoConcatMode: 'sequential',
          videoClipDuration: 5,
          videoCount: 1,
          bgmType: 'path',
          textBackgroundColor: 'transparent',
          nThreads: 2,
          paragraphNumber: 1,
          principalId: this.params.principalIdListData ? this.params.principalIdListData.split(',')[0] : '',
          principalName: this.params.principalIdListData ? this.params.principalIdListData.split(',')[1] : '',
          carSeriesId: this.params.carSeriesIdListData ? this.params.carSeriesIdListData.split(',')[0] : '',
          carSeriesName: this.params.carSeriesIdListData ? this.params.carSeriesIdListData.split(',')[1] : '',
          voiceName: this.params.currentDubbingIndex >= 0 ? this.dubbingList[this.params.currentDubbingIndex].name : '',
          bgmFile: this.params.currentMusicIndex >= 0 ? this.musicList[this.params.currentMusicIndex].path : '',
          bgmName: this.params.currentMusicIndex >= 0 ? this.musicList[this.params.currentMusicIndex].name : '',
          videoAspect,
          materialType,
          bgmVolume: this.params.bgmVolume / 10,
          fontName: this.params.fontName,
          subtitlePosition: this.params.subtitlePosition,
          textForeColor: this.params.textForeColor,
          strokeColor: this.params.strokeColor,
          fontSize: this.params.fontSize,
          strokeWidth: this.params.strokeWidth,
          subtitleEnabled,
        },
      };
      console.log(params);
      this.submitLoading = true;
      const { code, message } = await api.createAiVideoTask(params).finally(() => (this.submitLoading = false));
      if (code === 200) {
        this.$message.success('提交成功');
        this.openInfo();
      } else {
        this.$message.error(message);
      }
    },
    getTextName(text) {
      if (text) {
        return text.split(',').length > 0 ? text.split(',')[1] : text;
      }
      return text;
    },
    handleIndexClick(index, key) {
      if (this.params[key] === index) {
        this.params[key] = undefined;
      } else {
        this.params[key] = index;
      }
    },
    handleOpenAi() {
      this.showAiDrawer = true;
      this.aiLoading = true;
      this.$nextTick(() => {
        const iframe = document.getElementById('AiIframe');
        const _this = this;
        if (iframe.attachEvent) {
          iframe.attachEvent('onload', function () {
            _this.aiLoading = false;
          });
        } else {
          iframe.onload = function () {
            _this.aiLoading = false;
          };
        }
      });
    },
    openInfo() {
      const key = new Date().getTime();
      this.$notification.open({
        key,
        message: '生成视频中',
        description: '可在任务列表查看进度、下载成片',
        btn: (h) => {
          return h(
            'a-button',
            {
              props: {
                type: 'primary',
                size: 'small',
              },
              on: {
                click: () => {
                  const url = this.$router.resolve({
                    path: '/xhsAgency/AiDrawLog',
                  });
                  window.open(url.href, '_blank');
                },
              },
            },
            '查看任务（4s）'
          );
        },
      });
      let timeout = 4;
      const timeInterval = setInterval(() => {
        timeout--;
        this.$notification.open({
          key,
          message: '生成视频中',
          description: '可在任务列表查看进度、下载成片',
          btn: (h) => {
            return h(
              'a-button',
              {
                props: {
                  type: 'primary',
                  size: 'small',
                },
                on: {
                  click: () => {
                    this.$notification.close(key);
                    clearInterval(timeInterval);
                    const url = this.$router.resolve({
                      path: '/xhsAgency/AiDrawLog',
                      query: { type: 'video' },
                    });
                    window.open(url.href, '_blank');
                  },
                },
              },
              `查看任务（${timeout}s）`
            );
          },
        });
        if (timeout <= 0) {
          this.$notification.close(key);
          clearInterval(timeInterval);
        }
      }, 1000);
    },
  },
  mounted() {
    this.getMaterialList();
  },
};
</script>

<style lang="less" scoped>
.AiVideo {
  padding: 10px;
  display: flex;
  background-color: #f0f2f5;
}

.left_box {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  border-radius: 8px;
  background-color: #fff;
  min-height: 700px;

  .left_top {
    flex: 1;
    display: flex;

    .btn_column {
      width: 75px;
      border-right: 1px solid #d7d7d7;

      .btn_item {
        text-align: center;
        line-height: 36px;
        border: 1px solid #d7d7d7;
        border-right: 0;
        border-radius: 3px;
        cursor: pointer;

        &:first-child {
          border-radius: 8px 3px 3px 3px;
        }

        &:hover {
          background-color: #f0f2f5;
        }

        &.active_btn {
          color: #1890ff;
          border: 1px solid #1890ff;
        }
      }
    }

    .config_column {
      padding: 10px;
      width: 380px;
      max-height: 700px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-track {
        background: #fff;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: #00152984;

        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #0015294c;
      }

      &::-webkit-scrollbar-corner {
        background: #fff;
      }

      .config_title {
        margin-bottom: 10px;
        color: #373737;
      }

      .config_tab_item {
        position: relative;
        margin: 0 10px 10px 0;
        padding: 5px 20px;
        border: 1px solid #d9d9d9;
        cursor: pointer;
        user-select: none;
      }

      .music_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .music_item {
          position: relative;
          margin-bottom: 10px;
          padding: 5px 20px;
          width: 48%;
          min-height: 50px;
          border: 1px solid #d9d9d9;
          background-color: #f1f3f4;
          cursor: pointer;
          user-select: none;
        }
      }

      .active_item {
        border: 2px solid #58afff !important;
        color: #58afff;
      }

      .current_check_box {
        position: absolute;
        top: 1px;
        right: 5px;
      }

      .color_box {
        display: flex;
        margin-bottom: 15px;
        padding-right: 15px;

        .color_left {
          width: 100px;
          .color_item {
            margin-left: 10px;
            width: 30px;
            height: 30px;
            border: 1px solid #ddddde;
            border-radius: 5px;
            cursor: pointer;
          }
        }

        .color_right {
          flex: 1;
        }
      }
    }
  }

  .left_bottom {
    height: 50px;
    line-height: 50px;
    padding-left: 80px;
    border-top: 1px solid #d7d7d7;
  }
}

.right_box {
  flex: 1;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  border-radius: 8px;
  background-color: #fff;
  min-height: 700px;
  color: #000000;

  .show_item {
    position: relative;
    display: flex;
    margin-top: 10px;
    padding: 10px 15px;
    width: 80%;
    background-color: #f2f2f2;

    .item_title {
      margin-right: 10px;
    }
    .info_logo {
      position: absolute;
      right: -26px;
      color: red;
    }
  }
}

.iframe_container {
  height: 90vh;

  .iframe {
    width: 100%;
    height: 100%;
  }
}

.word_count {
  position: absolute;
  bottom: -23px;
  right: 6px;
  margin-left: 10px;
  background-color: #fff;
  color: #999999;
  z-index: 1;
}

// audio::-webkit-media-controls-fullscreen-button {
//   display: none;
// }
// // 进度条
// audio::-webkit-media-controls-timeline {
//   display: none;
// }
// //观看的当前时间
// audio::-webkit-media-controls-current-time-display {
//   display: none;
// }
// //剩余时间
// audio::-webkit-media-controls-time-remaining-display {
//   display: none;
// }
// //音量按钮
// audio::-webkit-media-controls-mute-button {
//   display: none;
// }
// // 字幕按钮
// audio::-webkit-media-controls-toggle-closed-captions-button {
//   display: none;
// }
// //音量的控制条
// audio::-webkit-media-controls-volume-slider {
//   display: none;
// }
// // 控件的容器
// audio::-internal-media-controls-overflow-button {
//   display: none !important;
// }

// audio::-webkit-media-controls-volume-control-container {
//   display: none;
// }

// audio::-internal-media-controls-overflow-button {
//   display: none;
// }
</style>
