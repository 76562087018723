export const btnList = [
  {
    text: '通用',
    value: 'common',
  },
  {
    text: '文案',
    value: 'writing',
  },
  {
    text: '视频',
    value: 'video',
  },
  {
    text: '配音',
    value: 'dubbing',
  },
  {
    text: '音乐',
    value: 'music',
  },
  {
    text: '字幕',
    value: 'subtitles',
  }
];

export const positionList = [
  {
    text: '顶部',
    value: 'top',
  },
  {
    text: '居中',
    value: 'center',
  },
  {
    text: '底部（推荐）',
    value: 'bottom',
  },
]
